<template>
	<screen size="large" class="details-view pa-0">
		<guest-template
			slot="content"
			navHeaderTitle="Find a Nonprofit"
			:showNavHeader="true"
			:showNavFooter="false"
			:routerPath="prevRoute"
			:divider="true"
		>
			<!-- No active organization banner -->
			<div
				v-if="!isActiveOrg"
				class="details-view-banner roundup-row"
				style="width: 100%; justify-content: flex-start; align-items: center"
			>
				<div style="flex: 1" />
				<div
					class="details-view-banner-content roundup-row"
					style="justify-content: space-between; align-items: center"
				>
					<div
						style="display: flex; flex-direction: column; justify-content: center"
					>
						<p
							v-if="institution && institution.name"
							class="content-top"
						>
							Start supporting
						</p>
						<p
							v-if="institution && institution.name"
							class="content-bottom"
						>
							{{ institution.name }}
						</p>
					</div>
					<RoundupButton class="white" @click="activateInstitution">{{
						hasOrg ? 'Activate Nonprofit' : 'Select Nonprofit'
					}}</RoundupButton>
				</div>
				<div style="flex: 1"></div>
			</div>

			<!-- Has an active organization - banner -->

			<div
				v-else
				class="details-view-banner-active roundup-row"
				style="width: 100%; justify-content: flex-start; align-items: center"
			>
				<div style="flex: 1" />
				<div
					class="details-view-banner-content roundup-row"
					style="justify-content: space-between; align-items: center"
				>
					<div
						class="roundup-row"
						style="justify-content: flex-start; align-items: center"
					>
						<div
							v-if="activeOrg.logo_url"
							class="inst-logo"
							:style="{
								backgroundImage:
									'url(' + activeOrg.logo_url + ')'
							}"
						></div>
						<v-icon v-else class="inst-icon">
							fas fa-hand-holding-heart
						</v-icon>
						<div
							class="ml-4"
							style="display: flex; flex-direction: column; justify-content: center"
						>
							<p class="content-top">
								You're donating to
							</p>
							<p
								v-if="institution && institution.name"
								class="content-bottom"
							>
								{{ institution.name }}
							</p>
						</div>
					</div>
					<RoundupButton
						:loading="pauseLoading"
						class="clear-white"
						@click="onPauseDonations"
						style="flex: 1;
					border: 1px solid white;
					margin-top: 0px;
					color: white;
					border-radius: 4px !important;
					max-width: 172px !important;"
					>
						Pause Donations</RoundupButton
					>
				</div>
				<div style="flex: 1"></div>
			</div>
			<div class="pa-0 mx-0">
				<SearchDetails
					:showForm="false"
					:uuid="uuid"
					breakpoint="xs"
					:showFooter="false"
					style="max-width: 772px !important"
					@institution="setInstitution"
					:showHowItWorks="false"
				/>
				<v-overlay :value="isLoading">
					<v-progress-circular
						indeterminate
						size="64"
					></v-progress-circular>
				</v-overlay>
				<v-overlay :value="showErrorModal()">
					<v-card
						class="mx-auto"
						max-width="512"
						light
						v-if="showErrorModal()"
					>
						<v-card-title>Uh Oh!</v-card-title>
						<v-card-text>{{
							error ? error.message : ''
						}}</v-card-text>
						<v-card-actions>
							<v-btn color="primary" @click="hideModal()">
								Close
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-overlay>
			</div>
			<modal v-model="showPauseDonations" size="flex">
				<div class="pause-modal">
					<h3>Pause Donations Confirmation</h3>
					<p>
						This will deactivate all of your selected nonprofits,
						and the RoundUp at Checkout embed will stop displaying
						on your checkout page.
					</p>
					<p>Do you want to continue?</p>
					<div class="roundup-row info">
						<img
							src="@/assets/purpleInfo.png"
							style="height: 25px; width: 25px; margin-right: 10px"
						/>
						Want to give to a different cause? Simply select
						“Activate” for a nonprofit in your Selected Nonprofits
						list, or use the Search to find a new cause to give to.
					</div>
					<div
						class="roundup-row"
						style="justify-content: flex-start"
					>
						<RoundupButton @click="onPauseConfirm" rounded
							>Confirm</RoundupButton
						>
						<RoundupButton
							rounded
							@click="closeModal"
							type="border"
							class="white"
							style="margin-left: 10px"
							>Back</RoundupButton
						>
					</div>
				</div>
			</modal>
		</guest-template>
	</screen>
</template>

<script>
import guestTemplate from '../../templates/guestTemplate.vue'
import Screen from '../../ui/Screen.vue'
import Modal from '../../ui/Modal'
import { mapActions, mapGetters } from 'vuex'

export default {
	components: { guestTemplate, Screen, Modal },
	name: 'DetailsView',

	data() {
		return {
			uuid: this.$route.params.uuid,
			isLoading: false,
			error: null,
			institution: null,
			showPauseDonations: false,
			pauseLoading: false,
			prevRoute: 'search.results'
		}
	},
	computed: {
		...mapGetters(['institutionList']),
		activeOrg() {
			return (
				this.institutionList.active[0] &&
				this.institutionList.active[0].institution
			)
		},
		isActiveOrg() {
			let match = false
			if (this.activeOrg) {
				match =
					this.institutionList.active[0].institution.uuid ===
					this.uuid
			}
			return match
		},
		hasOrg() {
			let hasOrgInList = false
			if (this.institutionList.list) {
				this.institutionList.list.forEach(el => {
					if (el.institution.uuid === this.uuid) {
						hasOrgInList = true
					}
				})
			}
			return hasOrgInList
		}
	},
	methods: {
		...mapActions([
			'attachInstitution',
			'pauseDonations',
			'listMyInstitutions'
		]),
		activateInstitution() {
			this.isLoading = true
			this.attachInstitution(this.institution.id)
				.then(() => {
					this.isLoading = false
					this.$swal({
						toast: true,
						position: 'top-end',
						timer: 3000,
						showConfirmButton: false,
						title: 'Success',
						text: `You are now donating to ${this.institution.name}`
					})
				})
				.then(() => {
					setTimeout(() => {
						this.$router.push({ name: 'select-cause' })
					}, 2000)
				})
				.catch(error => {
					this.isLoading = false
					this.$swal('Error', error.response.data.message)
				})
		},
		setInstitution(inst) {
			if (this.isActiveOrg) {
				this.institution = this.institutionList.active[0].institution
				return
			}
			this.institution = inst
		},
		showErrorModal() {
			return this.error && this.error.message
		},
		hideModal() {
			this.error = null
		},
		onPauseDonations() {
			this.showPauseDonations = true
		},
		onPauseConfirm() {
			this.pauseDonations()
			this.closeModal()
		},
		closeModal() {
			var evObj = document.createEvent('Events')
			evObj.initEvent('click', true, false)
			document.getElementById('modal-close').dispatchEvent(evObj)
		}
	},

	mounted() {
		if (this.institutionList.list.length === 0) {
			this.listMyInstitutions()
		}
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.prevRoute = from.name
		})
	}
}
</script>
<style lang="scss" scoped>
@import '@roundupapp/component-library/src/styles/index';

.details-view {
	p {
		margin: 0;
		color: white;
	}
	&-banner {
		padding: 0px 20px 0px 20px;
		height: 80px;
		background: $roundup-primary;
		.details-view-banner-icon {
			color: white;
			font-size: 16px;
		}
		.details-view-banner-label {
			color: white;
			font-weight: bold;
		}
		.details-view-banner-content {
			max-width: 732px;
			flex: 10;
		}
		.content-top {
			line-height: 1.125;
		}
		.content-bottom {
			font-size: 20px;
			font-weight: 600;
		}
	}
	&-banner-active {
		padding: 0px 20px 0px 20px;
		height: 80px;
		background: linear-gradient($roundup-bluegrad1, $roundup-bluegrad2);
		.details-view-banner-icon {
			color: white;
			font-size: 16px;
		}
		.details-view-banner-label {
			color: white;
			font-weight: bold;
		}
		.details-view-banner-content {
			max-width: 732px;
			flex: 10;
		}
		.content-top {
			line-height: 1.125;
		}
		.content-bottom {
			font-size: 20px;
			font-weight: 600;
		}
		.inst-logo {
			height: 50px;
			width: 50px;
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			border-radius: 10px;
		}
		.inst-icon {
			font-size: 45px;
			border-radius: 5px;
			color: white;
		}
	}
	.pause-modal {
		width: 500px;
		h3 {
			font-size: 20px;
			color: $roundup-charcoal !important;
		}
		p {
			margin-left: 0px;
			margin-bottom: 1rem;
			font-size: 16px;
			color: $roundup-charcoal !important;
		}
		.roundup-row {
			&.info {
				background-color: #e9edfa !important;
				color: #4337a7;
				font-size: 16px;
				border-radius: 5px;
				padding: 10px 20px;
				margin-bottom: 1rem;
			}
		}
	}
}

.details-view::v-deep .screen-sub {
	display: none;
}

.details-view::v-deep .screen-header {
	margin: -20px 0px 0px 0px;
}
</style>
